import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'admin',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'serveur',
    loadChildren: () => import('./pages/serveur/serveur.module').then( m => m.ServeurPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'serveur/:id',
    loadChildren: () => import('./pages/serveur-detail/serveur-detail.module').then( m => m.ServeurDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'popover-article-add-cart',
    loadChildren: () => import('./pages/popover-article-add-cart/popover-article-add-cart.module').then( m => m.PopoverArticleAddCartPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cart',
    loadChildren: () => import('./pages/cart/cart.module').then( m => m.CartPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then( m => m.AdminPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin-stock',
    loadChildren: () => import('./pages/admin-stock/admin-stock.module').then( m => m.AdminStockPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin-serveur',
    loadChildren: () => import('./pages/admin-serveur/admin-serveur.module').then( m => m.AdminServeurPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'popover-produit-options',
    loadChildren: () => import('./pages/popover-produit-options/popover-produit-options.module').then( m => m.PopoverProduitOptionsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'popover-serveuse-options',
    loadChildren: () => import('./pages/popover-serveuse-options/popover-serveuse-options.module').then( m => m.PopoverServeuseOptionsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'journal',
    loadChildren: () => import('./pages/admin-journal/admin-journal.module').then( m => m.AdminJournalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'depense',
    loadChildren: () => import('./pages/depense/depense.module').then( m => m.DepensePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'journal-detail',
    loadChildren: () => import('./pages/admin-journal-detail/admin-journal-detail.module').then( m => m.AdminJournalDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'stock-journal',
    loadChildren: () => import('./pages/admin-stock-journal/admin-stock-journal.module').then( m => m.AdminStockJournalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'stock-journal-detail',
    loadChildren: () => import('./pages/admin-stock-journal-detail/admin-stock-journal-detail.module').then( m => m.AdminStockJournalDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'popover-licence-form',
    loadChildren: () => import('./pages/popover-licence-form/popover-licence-form.module').then( m => m.PopoverLicenceFormPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin-stock-menu',
    loadChildren: () => import('./pages/admin-stock-menu/admin-stock-menu.module').then( m => m.AdminStockMenuPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin-stock-etat',
    loadChildren: () => import('./pages/admin-stock-etat/admin-stock-etat.module').then( m => m.AdminStockEtatPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'parametre',
    loadChildren: () => import('./pages/parametre/parametre.module').then( m => m.ParametrePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'popover-encaissement',
    loadChildren: () => import('./pages/popover-encaissement/popover-encaissement.module').then( m => m.PopoverEncaissementPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'popover-select-printer',
    loadChildren: () => import('./pages/popover-select-printer/popover-select-printer.module').then( m => m.PopoverSelectPrinterPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'popover-set-password',
    loadChildren: () => import('./pages/popover-set-password/popover-set-password.module').then( m => m.PopoverSetPasswordPageModule)
  },
  {
    path: 'admin-journal-cumul',
    loadChildren: () => import('./pages/admin-journal-cumul/admin-journal-cumul.module').then( m => m.AdminJournalCumulPageModule)
  },
  {
    path: 'admin-stock-categorie',
    loadChildren: () => import('./pages/admin-stock-categorie/admin-stock-categorie.module').then( m => m.AdminStockCategoriePageModule)
  },
  {
    path: 'prt-vente',
    loadChildren: () => import('./pages/prt-vente/prt-vente.module').then( m => m.PrtVentePageModule)
  },
  {
    path: 'prt-stock',
    loadChildren: () => import('./pages/prt-stock/prt-stock.module').then( m => m.PrtStockPageModule)
  },
  {
    path: 'prt-article',
    loadChildren: () => import('./pages/prt-article/prt-article.module').then( m => m.PrtArticlePageModule)
  },
  {
    path: 'prt-etat-stock',
    loadChildren: () => import('./pages/prt-etat-stock/prt-etat-stock.module').then( m => m.PrtEtatStockPageModule)
  },
  {
    path: 'prt-journal',
    loadChildren: () => import('./pages/prt-journal/prt-journal.module').then( m => m.PrtJournalPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
