import { Component } from '@angular/core';
import { GlobalService } from './services/global.service';
import { Storage } from '@ionic/storage-angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  
  public appPages = [
    {
      title: 'Acceuil',
      url: 'admin',
      icon: 'bar-chart'
    },
    {
      title: 'Caisse du jour',
      url: 'serveur',
      icon: 'calculator'
    },
    {
      title: 'Recettes',
      url: 'journal',
      icon: 'receipt'
    },
    {
      title: 'Magasin',
      url: 'admin-stock-menu',
      icon: 'receipt'
    },
    {
      title: 'Employés',
      url: 'admin-serveur',
      icon: 'people'
    },
    {
      title: 'Parametres',
      url: 'parametre',
      icon: 'options'
    }
  ];
  constructor(
    public globalService: GlobalService,
    private storage: Storage) {
    }
  
    async ngOnInit() {
      await this.storage.create();
      this.localGetLogin();
    }

  localGetLogin(){
    this.storage.get('loginUser').then((val) => {
      if(val){
        console.log('loginUser restored with',val);
        this.globalService.isConnected = true;
        this.globalService.loginUser = val;
        this.globalService.replaceHistory('admin');
      } else {
        this.globalService.showLoginForm = true;
      }
    });
  }
}
