import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { GlobalService } from './services/global.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private globalService: GlobalService
    ) {}
    canActivate(): boolean {
      if (!this.globalService.isConnected) {
        this.globalService.replaceHistory("/login");
      }
      return this.globalService.isConnected;
    }
  
}
