import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController, PopoverController } from '@ionic/angular';
import { Router, NavigationExtras } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { AngularFirestore } from '@angular/fire/firestore';
// import { sep } from 'path';

// import { Storage } from '@ionic/storage';
import { Storage } from '@ionic/storage-angular';
// import { PopoverLicenceFormPage } from '../pages/popover-licence-form/popover-licence-form.page';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  nom_app: string = "Manager App.";
  version_app: string = "v1.0.8";
  apiUrlRoot: string = 'https://manager-backend.expertizlab.com/';
  loading: any;
  nbrOfselected: number;
  cart: any = [];
  cart_montant: number = 0;
  cart_benefice: number = 0;
  montant_recu: number = 0;
  monnaie: number = 0;
  valeurStock: number = 0;
  isConnected: boolean;
  loginUser: any;
  selectedServeuse = {
    id:"",
    nom: "",
    tel: "",
    base64Image: "",
    photo: "",
    date_creation: null,
    active: true,
    date_maj: null,
    commandes:[],
    dette: 0
  }
  selectedProduit = {
    id:"",
    nom: "",
    prix: null,
    prix_achat: null,
    image: "",
    unite: 1,
    stock: 0,
    base64Image: "assets/imgs/placeholder.jpg",
    fournisseur: "",
    tel_fournisseur: "",
    date_creation: null,
    type: null,
    active: true,
    categorie:null
  };

  public ets = {
    id: null,
    nom: "",
    tel: "",
    password: "",
    produits: [],
    serveuses: [],
    journal: [],
    licence: [],
    categories: [],
    commandes: {
      jour1: []
    },
    parametres: {
      jour_activite: 1,
      periodeValidite: 4,
      expiration: "",
      first_launch: false,
      payer_plus_tard: true,
      benefice: true,
      text_merci: "",
      printer_config: false,
      printer_name: null,
      printer_address: null,
      autoprint: false,
      fast_add_cart_btn: false,
      secure: false,
      password: null,
      sync: false,
      sync_password: null,
      sync_realtime: false,
      tentative_connexion_echoue: 0,
      remise: false,
      shop_view: 'list',
      commande_couter: 0
    },
    date_creation: null,
    stockOperation: {},
  };
    
    nom_commande: string = 'cmd';
    serveur_recette_total: number = 0;
    serveur_dette_total: number = 0;
    recette_du_jour: number = 0;
    benefice_du_jour: number = 0;
    caisse_du_jour:  number = 0;
    impaye_du_jour:  number = 0;
    depense_du_jour = [];
    showClotureButton: boolean = false;
  msgErreur: string;
  listProduits: any;
  valeurStockPA: number;
  listServeuse: any[];
  listServeuseCache: any[];
  selectedIndex: number = 0;
  public showLoginForm: boolean = false;
  last_update_date: any;
  remise_du_jour: number = 0;
  // selectedPrinter: string;

  constructor(
    private loadingController: LoadingController,
    private alertController: AlertController,
    private toast: ToastController,
    private router: Router,
    private location: Location,
    private storage: Storage,
    private popoverController: PopoverController,
    // public afStore: AngularFirestore,
    public http: HttpClient,
  ) { }


  formatPrixToCfa2(montant: number){
    return new Intl.NumberFormat("fr-FR").format(montant);
  }

  formatPrixToCfa(montant: number) {
    let cleaned = ('' + montant).replace(/\D/g, '');
    let match4 = cleaned.match(/^(\d{1})(\d{3})$/);
    let match5 = cleaned.match(/^(\d{2})(\d{3})$/);
    let match6 = cleaned.match(/^(\d{3})(\d{3})$/);
    let match7 = cleaned.match(/^(\d{1})(\d{3})(\d{3})$/);
    let match8 = cleaned.match(/^(\d{2})(\d{3})(\d{3})$/);
    let match9 = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
    let match10 = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{3})$/);

    if (match4) return match4[1] + ' ' + match4[2];
    if (match5) return match5[1] + ' ' + match5[2];
    if (match6) return match6[1] + ' ' + match6[2];
    if (match7) return match7[1] + ' ' + match7[2] + ' ' + match7[3];
    if (match8) return match8[1] + ' ' + match8[2] + ' ' + match8[3];
    if (match9) return match9[1] + ' ' + match9[2] + ' ' + match9[3];
    if (match10) return match10[1] + ' ' + match10[2] + ' ' + match10[3] + ' ' + match10[4];
    
    return montant
  }

  formatPhoneNumber(tel) {
    let cleaned = ('' + tel).replace(/\D/g, '');
    let match8 = cleaned.match(/^(\d{2})(\d{2})(\d{2})(\d{2})$/);
    let match10 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

    if (match8) {
      return match8[1] + ' ' + match8[2] + ' ' + match8[3] + ' ' + match8[4];
    } else if (match10) {
      return match10[1] + ' ' + match10[2] + ' ' + match10[3];
    };
    return tel
  }


  // initProduitList(type?: string, categorie?: string) {
  //   let list = this.ets.produits.filter((item) => { // all active products
  //     return item.active;
  //   });
    
  //   if(type){
  //     list = list.filter((item) => { // type filter
  //       return (item.active && item.type == type);
  //     });
  //   } 

  //   if(categorie) {
  //     list = list.filter((item) => { // categorie filter
  //       return (item.active && item.categorie == categorie);
  //     });
  //   } 
    
  //   if(list){
  //     list.sort((a, b) => {
  //       let fa = a.nom.toLowerCase(),
  //         fb = b.nom.toLowerCase();
  //         if (fa < fb) { return -1;}
  //         if (fa > fb) {return 1;}
  //         return 0;
  //     });
  //   }
  //   return list;
  // }

  initProduitList(type?: string, categorie?: string) {
    let list = this.ets.produits.filter((item) => { // all active products
      return item.active;
    });
    
    if(type){
      list = list.filter((item) => { // type filter
        return (item.active && item.type == type);
      });
    } 

    if(categorie && categorie=="nc") {
      list = list.filter((item) => { // not categorie filter
        return (item.active && !item.categorie);
      });
    }
    else if(categorie && categorie=="alerte") {
      list = list.filter((item) => { // not categorie filter
        return (item.active && item.stock <=5);
      });
    }
    else if(categorie) {
      list = list.filter((item) => { // categorie filter
        return (item.active && item.categorie == categorie);
      });
    } 
    
    if(list){
      list.sort((a, b) => {
        let fa = a.nom.toLowerCase(),
          fb = b.nom.toLowerCase();
          if (fa < fb) { return -1;}
          if (fa > fb) {return 1;}
          return 0;
      });
    }
    return list;
  }

//   initProduitList(type?: string){
//     if(!type){
//       return this.ets.produits.filter((item) => {
//         return item.active;
//       });
//     } else{
//       return this.ets.produits.filter((item) => {
//         return (item.active && item.type == type);
//       });
//     }
// }

cloturer() {
  this.showClotureButton = false;
  this.ets.journal.forEach(element => {
    if (element.jour_activite == this.ets.parametres.jour_activite) {
      element.date_cloture = this.getCurrentDate();
      element.heure_cloture = this.getCurrentTime();
      element.recette = this.recette_du_jour;
      element.benefice = this.benefice_du_jour;
      element.remise_global = this.remise_du_jour;
    }
  });

  console.log("Jour " + this.ets.parametres.jour_activite + " terminé");
  this.ets.parametres.jour_activite++;
  this.ets.serveuses.forEach(ser => {
    ser.commandes['jour' + this.ets.parametres.jour_activite] = [];
  });
  this.initStockOperationOfDay();

}

detteCalculator(id_serv: string) {
  this.ets.serveuses.forEach(element => {
    if (element.id == id_serv) {
      let dette = 0;
      element.commandes['jour' + this.ets.parametres.jour_activite].forEach(cmd => {
        if (!cmd.isPaid) {
          dette += cmd.total;
        }
      });
      element.dette = dette;
    }
  });
}


  recetteCalculator() {
    this.recette_du_jour = 0;
    this.benefice_du_jour = 0;
    this.caisse_du_jour = 0;
    this.impaye_du_jour = 0;
    this.remise_du_jour = 0;
    if (this.haveCmdThisDay()) {
      console.log("stockop",this.ets.stockOperation['jour' + this.ets.parametres.jour_activite]);
      
      this.ets.stockOperation['jour' + this.ets.parametres.jour_activite].forEach(item => {
        this.recette_du_jour += (item.qte_vente * item.prix) - item.reduction;
        this.benefice_du_jour += (item.qte_vente * (item.prix - item.prix_achat))- item.reduction;
      });
      this.ets.stockOperation['jour' + this.ets.parametres.jour_activite].forEach(item => {
        if(item.id_produit == "-1"){
          this.remise_du_jour += item.prix;
        }
      });
    }
    this.ets.serveuses.forEach(element => {
      this.impaye_du_jour += element.dette;
    });
    this.caisse_du_jour = this.recette_du_jour - this.impaye_du_jour;
  }

  /**
   * @function haveCmdThisDay return true si une commande a deja ete passer aujourd'hui et false dans le cas contraire
   * 
  **/
  haveCmdThisDay(){
    let val = false;
        this.ets.serveuses.forEach(element => {
          if(element.commandes['jour'+this.ets.parametres.jour_activite]){
            if(element.commandes['jour'+this.ets.parametres.jour_activite].length > 0){
              val = true;
            }
          } else{
            console.log(" not init");
          }
        });
        return val;
  }

  initStockOperationOfDay() {
    if (!this.ets.stockOperation['jour' + this.ets.parametres.jour_activite]) {
      this.ets.stockOperation['jour' + this.ets.parametres.jour_activite] = [];
      let produitSList = this.initProduitList();
      produitSList.forEach(prod => {
        if (prod.active) {
          let data = {
            id_produit: prod.id,
            nom_produit: prod.nom,
            prix: prod.prix,
            prix_achat: prod.prix_achat,
            type: prod.type,
            qte_entree: 0,
            qte_sortie: 0,
            qte_vente: 0,
            reduction: 0,
            old_stock: prod.stock,
            stock_fin: prod.stock,
            date_maj: this.getCurrentDate(),
            heure_maj: this.getCurrentTime(),
            jour_activite: this.ets.parametres.jour_activite
          };
          this.ets.stockOperation['jour' + this.ets.parametres.jour_activite].push(data);
        }
      });

      let data1 = {
        id_produit: "-1",
        nom_produit: "Remise sur total",
        prix: 0,
        prix_achat: 0,
        type: null,
        qte_entree: 0,
        qte_sortie: 0,
        qte_vente: 0,
        reduction: 0,
        old_stock: null,
        stock_fin: null,
        date_maj: this.getCurrentDate(),
        heure_maj: this.getCurrentTime(),
        jour_activite: this.ets.parametres.jour_activite
      };
      this.ets.stockOperation['jour' + this.ets.parametres.jour_activite].push(data1);

      console.log("Stock Operation INITIALIZED",this.ets.stockOperation['jour' + this.ets.parametres.jour_activite]);
    } else {
      console.log("Stock ALREADY INITIALIZED",this.ets.stockOperation['jour' + this.ets.parametres.jour_activite]);
    }

  }

  initJournalOfDay() {
    this.ets.journal.unshift({
      jour_activite: this.ets.parametres.jour_activite,
      date_debut: '',
      heure_debut: '',
      date_cloture: '',
      heure_cloture: '',
      depenses: [],
      recette: null,
      total_depenses: null,
    });
    console.log("Journal Initialisé");
  }

  // addCommande(ispaid: boolean, serveur: string) {

  //   if (!this.haveCmdThisDay()) {
  //     this.initJournalOfDay();
  //     // this.initStockOperationOfDay();
  //     this.ets.journal.forEach(el => {
  //       if (el.jour_activite == this.ets.parametres.jour_activite) {
  //         el.date_debut = this.getCurrentDate();
  //         el.heure_debut = this.getCurrentTime();
  //       }
  //     });
  //   }

  //   let data = {
  //     "id": null,
  //     "nom_cmd": this.nom_commande,
  //     "isPaid": ispaid,
  //     "montant_recu": this.montant_recu,
  //     "monnaie": this.monnaie,
  //     "total": this.cart_montant,
  //     "benefice": this.cart_benefice,
  //     "date": this.getCurrentDate(),
  //     "heure": this.getCurrentTime(),
  //     "article": this.cart
  //   };

  //   this.ets.serveuses.forEach(element => {
  //     if (element.id == this.selectedServeuse.id) {
  //       data.id = this.format3number(element.commandes['jour' + this.ets.parametres.jour_activite].length + 1);
  //       element.commandes['jour' + this.ets.parametres.jour_activite].unshift(data);

  //       // MAJ du stock 
  //       this.cart.forEach(art => {
  //         this.addToStock(art.id, art.quantite, 'vente',art);
  //       });
  //     }
  //   });
  //   this.cart = null;
  //   console.log("cmd added cart vidé", this.cart);
  //   this.cart_montant = 0;
  //   this.cart_benefice = 0;
  //   this.montant_recu = 0;
  //   this.monnaie = 0;
  //   this.reinitListProduit();
  //   // this.recetteCalculator();
  //   this.detteCalculator(this.selectedServeuse.id);
  //   // this.localSave();
  //   return data;
  // }


  // payCommande(data: any, serveur?: string) {
  //   this.ets.serveuses.forEach(element => {
  //     if (element.id == this.selectedServeuse.id) {
  //       element.commandes['jour' + this.ets.parametres.jour_activite].forEach(cmd => {
  //         if (cmd.id == data.id) {
  //           cmd.isPaid = true;
  //           cmd.montant_recu = this.montant_recu;
  //           cmd.monnaie = this.monnaie;
  //           cmd.date = this.getCurrentDate();
  //           cmd.heure = this.getCurrentTime();
  //         }
  //       });
  //     }
  //   });
  //   this.detteCalculator(this.selectedServeuse.id);
  // }

  // annulerCommande(serveur: string) {
  //   console.log("serveur", serveur);
  //   this.cart = null;
  //   console.log("cmd cancel cart vidé", this.cart);
  //   this.cart_montant = 0;
  //   this.cart_benefice = 0;
  //   this.reinitListProduit();
  //   // this.goBack();
  //   this.naviguer('serveur/' + serveur);
  // }

  // addToStock(id_prod: string, qte: number, op: string, article?: any) {
  //   this.initStockOperationOfDay();
  //   let qte_entree = 0, qte_sortie = 0, qte_vente = 0, reduction = 0;

  //   if (op == 'vente') {
  //     qte_vente = qte;
  //     reduction = article.reduction * qte;
  //   } else if (op == 'in') {
  //     qte_entree = qte;
  //   } else if (op == 'out') {
  //     if (qte > this.selectedProduit.stock) {
  //       this.showAlert("Stock insuffisant!");
  //       return;
  //     } else {
  //       qte_sortie = qte;
  //     }
  //   }

  //   //MAJ DU STOCK RESTANT POUR LES ARTICLES
  //   this.initProduitList('article').forEach(element => {
  //     if (element.id == id_prod) {
  //       element.stock += parseInt(qte_entree.toString());
  //       element.stock -= parseInt(qte_vente.toString());
  //       element.stock -= parseInt(qte_sortie.toString());
  //     }
  //   });


  //   //MAJ DU MOUVEMENT DES STOCKS
  //   this.ets.stockOperation['jour' + this.ets.parametres.jour_activite].forEach(item => {
  //     if (item.id_produit == id_prod) {
  //       item.qte_vente = parseInt(item.qte_vente.toString()) + parseInt(qte_vente.toString());
  //       item.reduction = article && article.reduction ? parseInt(item.reduction.toString()) + parseInt(reduction.toString()): 0;

  //       if(item.type == "article"){
  //         item.qte_entree = parseInt(item.qte_entree.toString()) + parseInt(qte_entree.toString());
  //         item.qte_sortie = parseInt(item.qte_sortie.toString()) + parseInt(qte_sortie.toString());
  //         item.stock_fin = item.stock_fin + parseInt(qte_entree.toString()) - parseInt(qte_vente.toString()) - parseInt(qte_sortie.toString());
  //       }
  //       item.date_maj = this.getCurrentDate();
  //       item.heure_maj = this.getCurrentTime();
        
  //       // MAJ pour les remises global
  //       if(id_prod == "-1"){
  //         item.prix += article.prix;
  //         item.qte_vente = 1;
  //       }
  //     }
  //   });
  //   console.log("MAJ STOCK: " + id_prod + "/" + op + "/" + qte);
  // }



  getValeurStock(){
    this.valeurStock = 0;
    this.valeurStockPA = 0;
    this.initProduitList('article').forEach(element => {
      this.valeurStockPA += element.prix_achat * element.stock;
      this.valeurStock += element.prix * element.stock;
    });
  }

  /** 
   * @function reinitListProduit() Déselectionner les produit/services précedement ajoutés au panier
  */
  // reinitListProduit(){
  //   let n=0;
  //   this.ets.produits.forEach((element)=>{
  //       if(element.added){
  //         element.added = false;
  //         element.unite = null;
  //         n++;
  //       }
  //   });
  //   console.log(n+" articles vidés du panier");
  // }


  // format3number(nb: number){
  //   let out: string = ''+nb;
  //   if(out.length == 1) {
  //     out = '00'+nb;
  //   } else if(out.length == 2) {
  //     out = '0'+nb;
  //   }
  //   return out;
  // }

  getCurrentDateEn(nosep?: boolean) {
    var now = new Date(),
      day: string,
      month: string,
      year: string;
    month = (now.getUTCMonth() + 1).toString();
    year = (now.getUTCFullYear()).toString();
    day = now.getUTCDate().toString();

    if (day.toString().length == 1) {
      day = '0' + day;
    }
    if (month.toString().length == 1) {
      month = '0' + month;
    }
    return [year, month, day].join('-');
  }

  getCurrentDate(nosep?: boolean){
    var now     = new Date(),
    day: string,
    month: string,
    year: string;
    month = (now.getUTCMonth()+1).toString();
    year = (now.getUTCFullYear()).toString();
    day  = now.getUTCDate().toString();
  
    if(day.toString().length == 1) {
        day = '0'+day;
    }
    if(month.toString().length == 1) {
          month = '0'+month;
    }
    if(nosep) return day+month+year;
    else return day+'/'+month+'/'+year;
  }


  getCurrentDateFormat2(){
    var now     = new Date(),
    day: string,
    month: string,
    year: string;
    month = (now.getUTCMonth()+1).toString();
    day  = now.getUTCDate().toString();
    year = now.getUTCFullYear().toString().substring(2);
  
    if(day.toString().length == 1) {
        day = '0'+day;
    }
    if(month.toString().length == 1) {
          month = '0'+month;
    }
      return [year,month,day].join('');
  }



  formatCarbonDate(date: String){
    let 
    day = date.substring(8,10),
    month = date.substring(5,7),
    year = date.substring(0,4),
    heure = date.substring(11,13),
    min = date.substring(14,16);
    return [day,month,year].join('/')+' '+[heure,min].join(':');
  }

  toDate(date: String){
    let 
    day = date.substring(4,6),
    month = date.substring(2,4),
    year = date.substring(0,2);
    return [day,month,year].join('/');
  }

  getCurrentTime(){
    var now     = new Date(),
    heure: string,
    minute: string,
    second: string;
    heure = now.getUTCHours().toString();
    minute  = now.getUTCMinutes().toString();
    second  = now.getUTCSeconds().toString();
    
    if(heure.toString().length == 1) {
      heure = '0'+heure;
    }
    if(minute.toString().length == 1) {
         minute = '0'+minute;
    }
    if(second.toString().length == 1) {
        second = '0'+second;
    }
    return heure+':'+minute+':'+second;
  }

  prenom(item){
    let prenom = item.trim().split(' ');
    return prenom[prenom.length-1];
  }

  // localSave() {
  //   this.storage.set('dgco_manager_ets', this.ets).then(rep =>{
  //     console.log('dgco_manager_ets writting', rep);
  //   });
  // }


  async presentToast(msg: string, time: number) {
    const toast = await this.toast.create({
      message: msg,
      duration: time,
    });
    toast.present();
  }

  async showAlert(msg: string) {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      // header: 'Alert',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();
  }

  replaceHistory(url: string,item?: any) {
    this.naviguer(url,item);
    this.location.replaceState(url);
  }


  naviguer(page: string, item?: any, other?: any){
    let
    navigationExtras: NavigationExtras = {
      state: {
        item: item,
        other: other
      }
    }
    this.router.navigateByUrl(page, navigationExtras);
  }

  goBack() {
    this.location.back();
  }


  nousContactez(contact: string){
    let contactMsg = encodeURI(`Code App: *${this.ets.id}* \nBonjour, je vous écris concernant *MANAGER* `);
    this.showAlert(` <a href="http://wa.me/${contact}?text=${contactMsg}" target="_blank">Cliquez ici pour nous contacter sur Whatsapp</a>`);
  }

  async showLoading(msg: string, dismissed?: boolean) {
    console.log('loading: '+dismissed);
    
    this.loading = await this.loadingController.create({
      spinner: "crescent",
      message: msg,
      backdropDismiss: dismissed == undefined ? true : dismissed,
    });
    return await this.loading.present();
  }

  dismissLoading(){
    console.log('dismmissed');
    return this.loading.dismiss();
  }

    /**
       * @nom generateCode()
       * @description generer un code aleatoire
       * @param1 type: number, valeur maximum
       * @param2 type: number, valeur minimum
    */
  generateCode(max: number, min: number){
    var code= Math.floor(Math.random() * (max -min + 1)) + min;
  return code;
  }


    /**
       * @description Creer une licence par rapport a une date d'expiration
       * @param1  identifiant du destinataire
       * @param2 date d'expiration au format aammjj
    */
     licenceEncoder(identifiant: number, expiration: number){
      let 
      annee = parseInt(this.getCurrentDate(true).substring(4)),  
      rand = this.generateCode(9,2);
      let final, part1, part2;
            part1 = (rand * identifiant) + annee, 
            part2 = expiration * rand;
              if(part1.toString().length==4) part1 = '0'+part1;
              if(part2.toString().length==6) part2 = '0'+part2;

            final = part1+part2.toString()+rand; 
            console.log(part1+" | "+part2+" | "+rand);
            
          console.log(final); 
          this.showAlert("la licence est <b>"+final+"</b>");
    }


  // licenceDecoder(code: string){
  //   this.msgErreur = '';
  //   let today = parseInt(this.getCurrentDateFormat2());

  //   if(!this.inArray(code,this.ets.licence)){
  //     let new_expiration: number, calcLicence,
  //       part1 = code.substring(0,5),
  //       part2 = code.substring(5,12),
  //       rand = code.substring(12),
  //       annee = parseInt(this.getCurrentDate(true).substring(4));
  //       if(parseInt(rand)>1){
  //           calcLicence = (parseInt(part1) - annee) / parseInt(rand);
  //           new_expiration = parseInt(part2) / parseInt(rand);
  //           console.log("identifiant:",calcLicence);
  //           console.log("new_expiration:",new_expiration);
  //           console.log("annee:",annee);
  //           console.log("rand:",rand);
  //           if(this.isInt(new_expiration)&&this.isInt(calcLicence)){

  //             if(new_expiration>200101 && new_expiration <=301231){
  //               if(calcLicence == this.ets.id){
                  
  //                 // if(parseInt(this.ets.parametres.expiration) < new_expiration){
  //                   this.ets.parametres.expiration = ''+new_expiration;
  //                   this.ets.licence.push(code);
  //                   // this.localSave();
  //                   this.showConfirmLicence("Licence enregistré","Votre abonnement a été prolonger jusqu'au "+(this.toDate(''+new_expiration))+".<br> ** Merci d'utiliser MANAGER **");
  //                   return;
  //                 // } else{
  //                 //     this.msgErreur = "La date d'expiration actuelle est ultérieure à celle de la nouvelle licence";
  //                 // }
  //               } else{
  //                 this.msgErreur = "Licence non valide pour cet utilisateur";
  //               }
  //             } else{
  //               this.msgErreur = "Licence invalide. ERX0063";
  //             }
  //           } else{
  //             this.msgErreur = "Licence invalide. ERX0061";
  //           } 
  //         } else{
  //           this.msgErreur = "Licence invalide. ERX0062";
  //         } 
  //   } else {
  //     this.msgErreur = "Cette licence a déja été utilisée";
  //   }
  //   // this.abonnementPopover();
  // }
  
  inArray(text: string, tab:any) {
    for(var i = 0; i < tab.length; i++){
        if(tab[i] == text) return true;
    }
    return false;
  }

  isInt(n){
    return n % 1 === 0;
  }

  // async showConfirmLicence(titre: string, msg: string) {
  //   const alert = await this.alertController.create({
  //     backdropDismiss: false,
  //     header: titre,
  //     message: msg,
  //     buttons: [
  //       {
  //         text: 'OK',
  //         handler: () => {
  //           window.location.reload();
  //         }
  //       }
  //     ]
  //   });
  //   await alert.present();
  // }

  // getListServeuse(jour_activite: number) {
  //   this.listServeuse = [];
  //   this.listServeuseCache = [];
  //   this.ets.serveuses.forEach(element => {
  //     if (element.active) {
  //       let dette = 0;
  //       element.commandes['jour' + jour_activite].forEach(cmd => {
  //         if (!cmd.isPaid) {
  //           dette += cmd.total;
  //         }
  //       });
  //       element.dette = dette;
  //       this.listServeuse.push(element);
  //       this.listServeuseCache.push(element);
  //     }
  //   });
  //   console.log("serveuse list",this.listServeuse);
  // }


  getListServeuseAllWithVente(jour_activite: number) {
    console.log("GET LIST SERVEUR 1");
    console.log("jour_activite",jour_activite);
    this.listServeuse = [];
    this.listServeuseCache = [];
    this.ets.serveuses.forEach(element => {
      let dette = 0, nb_cmd = 0, total = 0;
      // if (element.active){
        this.ets.commandes['jour' + jour_activite].forEach(cmd => {
          if(cmd.id_serveur == element.id){
            if(cmd.active){
              if (!cmd.isPaid) {
                dette += cmd.total;
              } else {
                total += cmd.total;
              }
              nb_cmd++;
            }
          }
        });
        element.dette = dette;
        element.total = total;
        element.nb_commande = nb_cmd;
        if(total>0){
          this.listServeuse.push(element);
          this.listServeuseCache.push(element);
        }
      // }
    });
    console.log("list serveur",this.listServeuse);
  }

  getListServeuse(jour_activite: number) {
    console.log("GET LIST SERVEUR");
    console.log("jour_activite",jour_activite);
    
    this.listServeuse = [];
    this.listServeuseCache = [];
    this.recette_du_jour = this.impaye_du_jour = 0; 
    this.ets.serveuses.forEach(element => {
      let dette = 0, nb_cmd = 0, total = 0;
      if (element.active){
        this.ets.commandes['jour' + jour_activite].forEach(cmd => {
          if(cmd.id_serveur == element.id){
            if(cmd.active){
              if (!cmd.isPaid) {
                dette += cmd.total;
              } else {
                total += cmd.total;
              }
              nb_cmd++;
            }
          }
        });
        element.total = total;
        element.dette = dette;
        element.nb_commande = nb_cmd;
        this.recette_du_jour += dette+ total;
        this.impaye_du_jour += dette;
        this.listServeuse.push(element);
        this.listServeuseCache.push(element);
      }
    });
    this.caisse_du_jour = this.recette_du_jour - this.impaye_du_jour;
    console.log("list serveur",this.listServeuse);
  }


  callGetApi(path: string) {
    let headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.loginUser.token_type + ' ' + this.loginUser.access_token
    }),
      url: any = this.apiUrlRoot + path;
    return this.http.get(url, { headers: headers });
  }

  callPostApi(path: string, payload: any) {
    let headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.loginUser.token_type + ' ' + this.loginUser.access_token
    }),
      options: any = payload,
      url: any = this.apiUrlRoot + path;
    return this.http.post(url, JSON.stringify(options), { headers: headers })
  }


  disconnect() {
    this.replaceHistory('login');
    // this.loginUser = null;
    this.showLoginForm = true;
    this.storage.remove('loginUser');
    this.presentToast('Vous êtes déconnecté', 2000);
  }



}
